import request from '@/utils/request'

// 查询学校列表
export function listCategory (query) {
  return request({
    url: '/rongyi/resourceCategory/list',
    method: 'get',
    params: query
  })
}
// 查询学校列表 树结构
export function listCategoryTree (query) {
  return request({
    url: '/rongyi/resourceCategory/listTree',
    method: 'get',
    params: query
  })
}
// 查询学校详细
export function getCategory (categoryId) {
  return request({
    url: '/rongyi/resourceCategory/' + categoryId,
    method: 'get'
  })
}

// 新增学校
export function addCategory (data) {
  return request({
    url: '/rongyi/resourceCategory/',
    method: 'post',
    data: data
  })
}

// 修改学校
export function updateCategory (data) {
  return request({
    url: '/rongyi/resourceCategory/',
    method: 'put',
    data: data
  })
}

// 删除学校
export function delCategory (categoryId) {
  return request({
    url: '/rongyi/resourceCategory/' + categoryId,
    method: 'delete'
  })
}
