<template>
    <a-modal
        ref="createModal"
        :title="readOnly ? '详情' : form.resourceId ? '资源编辑' : '新增操作'"
        :width="640"
        :visible="visible"
        @cancel="close"
        @ok="confirm"
        :confirmLoading="confirmLoading"
    >
        <a-spin :spinning="loading">
            <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-row>
                    <a-col :span="24" :pull="3">
                        <a-form-model-item label="所属类别" prop="categoryId">
                            <a-input v-model="form.universityId" style="display:none" />
                            <a-tree-select  :disabled="readOnly"
                                v-model="form.categoryId"
                                :tree-data="categoryOptions"
                                style="width: 100%"
                                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                placeholder="Please select"
                                tree-default-expand-all
                                :replaceFields="treeReplaceFields"
                            />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24" :pull="3">
                        <a-form-model-item label="资源名称" prop="name">
                            <a-input v-model="form.name" placeholder="资源名称" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="类型" prop="contentType">
                            <a-select
                                v-model="form.contentType"
                                v-hasPermi="['organization:university:list']"
                            >
                                <a-select-option
                                    v-for="item in mediaOptions"
                                    :value="item.dictValue"
                                    :key="item.dictValue"
                                    >{{ item.dictLabel }}</a-select-option
                                >
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="状态" prop="status">
                            <a-radio-group button-style="solid" v-model="form.status">
                                <a-radio-button
                                    v-for="dict in statusOptions"
                                    :key="dict.dictValue"
                                    :value="dict.dictValue"
                                >
                                    {{ dict.dictLabel }}
                                </a-radio-button>
                            </a-radio-group>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24" :pull="3">
                        <a-form-model-item label="链接" prop="contentType" v-show="form.contentType == 4">
                            <a-form-model-item prop="resourcePath">
                            <a-input v-model="form.resourcePath" placeholder="资料链接" />
                            </a-form-model-item>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12" :pull="-3">
                        <template>
                            <a-upload :disabled="readOnly"
                                list-type="picture-card"
                                class="avatar-uploader"
                                :show-upload-list="false"
                                :headers="{
                                    Authorization: token,
                                }"
                                :action="config.apiDomain + '/file/upload'"
                                :before-upload="beforeUploadCover"
                                @change="handleChangeCover"
                            >
                                <img v-if="form.cover" :src="config.staticResourceDomain+form.cover" alt="avatar" class="avatar" />
                                <div v-else>
                                    <a-icon :type="loading ? 'loading' : 'plus'" />
                                    <div class="ant-upload-text">上传资源封面</div>
                                </div>
                            </a-upload>
                        </template>
                    </a-col>
                    <a-col :span="12">
                        <template>
                            <a-upload :disabled="readOnly"
                                list-type="picture-card"
                                class="avatar-uploader"
                                :show-upload-list="false"
                                :headers="{
                                    Authorization: token,
                                }"
                                :action="config.apiDomain + '/file/upload'"
                                :before-upload="beforeUploadResource"
                                @change="handleChangeResource"
                            >
                                <img v-if="form.resourcePath" :src="config.staticResourceDomain+form.resourcePath" alt="avatar" class="avatar" />
                                <div v-else>
                                    <a-icon :type="loading ? 'loading' : 'plus'" />
                                    <div class="ant-upload-text">上传资源文件</div>
                                </div>
                            </a-upload>
                        </template>
                    </a-col>
                </a-row>
            </a-form-model>
        </a-spin>
    </a-modal>
</template>

<script>
import { listCategory } from '@/api/rongyi/resource/category'
import { addResource, updateResource } from '@/api/rongyi/resource/resource'
// 表单字段
export default {
    data() {
        return {
            // 菜单列表
            menuOptions: [],
            // 部门列表
            categoryOptions: [],
            // 状态数据字典
            statusOptions: [],
            // 文件媒体类型数据字典
            mediaOptions:[],
            // 当前控件配置:
            confirmLoading: false,
            readOnly: false,
            visible: false,
            loading: false,
            // form: this.$form.createForm(this),
            // value: undefined,
            labelCol: {
                xs: { span: 12 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
            // 表单属性:
            form: {},
            // 表单校验
            rules: {
                categoryId: [{ required: true, message: '所属类别不能为空', trigger: 'blur' }],
                name: [{ required: true, message: '资源名称不能为空', trigger: 'blur' }],
                orderNum: [{ required: true, message: '菜单顺序不能为空', trigger: 'blur' }],
                contentType:[{ required: true, message: '请选择类型', trigger: 'blur' }],
                status:[{ required: true, message: '请选择状态', trigger: 'blur' }],
                email: [
                    {
                        type: 'email',
                        message: "'请输入正确的邮箱地址",
                        trigger: ['blur', 'change'],
                    },
                ],
                phone: [
                    {
                        pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                        message: '请输入正确的手机号码',
                        trigger: 'blur',
                    },
                ],
            },
            treeReplaceFields: {
                children: 'children',
                title: 'categoryName',
                key: 'categoryId',
                value: 'categoryId',
            },
            token:'',
            config:this.rongyiConfig,
        }
    },
    created() {
        this.getDicts('sys_enable').then((response) => {
            this.statusOptions = response.data
        })
        this.getDicts('file_media_type').then((response) => {
            this.mediaOptions = response.data
        })

        
    },
    methods: {
        loadCategory(){
            listCategory().then((response) => {
                const root = response.rows.find((item)=>{return item.parentId=='' || item.parentId == null})
                const rootId = root ? root.categoryId : ''
                this.categoryOptions = this.handleTree(response.rows, 'categoryId','parentId','children',rootId,true)
                // console.log('this.categoryOptions')
                // console.log(this.categoryOptions)

                // const category = { categoryId: 0, categoryName: '主类目', children: [] }
                // this.categoryOptions.push(category)
            })
        },
        // 由于要用传进来的值做判断,将显示和隐藏放在内部做处理
        show(data, readOnly, categoryOptions) {
            if (data) {
                // 修改行为
                this.form = Object.assign({}, data) || {}
            } else {
                // 新增行为
                this.reset()
            }
            this.readOnly = typeof readOnly !== 'undefined' ? readOnly === true : false
            this.visible = true
            if(categoryOptions){
                this.categoryOptions = categoryOptions;
            }else{
                this.loadCategory();
            }

        },
        //操作cover上传
        handleChangeCover(info) {
            const self = this
            if (info.file.status === 'uploading') {
                this.loading = true
                return
            }
            if (info.file.status === 'done') {
                console.log(info.file.response.data.name)
                this.form.cover = info.file.response.data.url
                this.loading = false
            }
        },
        beforeUploadCover(file) {
            console.log('beforeUpload')
            //获取token
            const tk = localStorage.getItem('token')
            if (tk) {
                this.token = 'Bearer ' + tk
            }

            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
            if (!isJpgOrPng) {
                this.$message.error('只能上传jpeg、png格式的图片文件!')
            }
            const isLt2M = file.size / 1024 / 1024 < 2
            if (!isLt2M) {
                this.$message.error('图像大小不能超过 2MB!')
            }
            return isJpgOrPng && isLt2M
        },
        //操作资源文件上传
        handleChangeResource(info) {
            const self = this
            if (info.file.status === 'uploading') {
                this.loading = true
                return
            }
            if (info.file.status === 'done') {
                console.log('info',info)
                console.log(info.file.response.data.name)
                this.form.resourcePath = info.file.response.data.url
                this.loading = false
            }
        },
        beforeUploadResource(file) {
            console.log('beforeUpload')
            //获取token
            const tk = localStorage.getItem('token')
            if (tk) {
                this.token = 'Bearer ' + tk
            }

            
            const sizeLimit = file.size / 1024 / 1024 < 200
            if (!sizeLimit) {
                this.$message.error('文件大小不能超过 200MB!')
            }
            return sizeLimit
        },
        // 关闭模态框
        close() {
            this.visible = false
            this.reset()
        },
        confirm() {
            let that = this
            this.confirmLoading = true
            this.$refs.ruleForm.validate((valid) => {
                let {hostId,userId} = this.$store.state.user
                console.log(typeof(hostId) == 'undefined')
                hostId = (typeof(hostId)=='undefined'?0:hostId)
                console.log(hostId)
                console.log(userId)
                that.form.universityId =  hostId || this.rongyiConfig.rongyiUniversityId || '00000000000000000000000000000000'
                console.log(that.form)
                if (valid) {
                    if (!this.isEmptyStr(that.form.resourceId)) {
                        console.log("beging Update")
                        // 进行修改行为:
                        // // 暂时在本处处理若依最高级菜单下无子菜单情况下children是个字符串的bug
                        // if (this.form.children === '') {
                        //     this.form.children = []
                        // }
                        // this.filterStrArrary(this.form.children)
                        // 刷新表格
                        updateResource(that.form).then((response) => {
                            if (response.code === 200) {
                                this.$message.success('修改成功')
                                // 关闭本组件
                                this.visible = false
                                // 调用外部刷新列表方法
                                this.$emit('handle-success')
                                // 刷新表单
                                this.reset()
                                this.confirmLoading = false
                            } else {
                                this.$message.error(response.msg)
                                this.confirmLoading = false
                            }
                        })
                    } else {
                        // 新增
                        addResource(this.form).then((response) => {
                            if (response.code === 200) {
                                this.$message.success('新增成功')
                                // 关闭本组件
                                this.visible = false
                                // 调用外部刷新列表方法
                                this.$emit('handle-success')
                                // 刷新表单
                                this.reset()
                                this.confirmLoading = false
                            } else {
                                this.$message.error(response.msg)
                                this.confirmLoading = false
                            }
                        })
                    }
                } else {
                    return (this.confirmLoading = false)
                }
            })
        },
        // 表单重置
        reset() {
            this.form = {}
        },
        filterStrArrary(list) {
            for (let index = 0; index < list.length; index++) {
                if (list[index].children === '') {
                    list[index].children = []
                }
                if (list[index].children) {
                    this.filterStrArrary(list[index].children)
                }
            }
        },
    },
}
</script>

<style scoped>
.avatar {
    width: 128px;
    height: 128px;
}
.avatar-uploader {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
}
.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}
</style>