import request from '@/utils/request'

// 查询资源列表
export function listResource (query) {
  return request({
    url: '/rongyi/resource/list',
    method: 'get',
    params: query
  })
}

// 查询学校详细
export function getResource (resourceId) {
  return request({
    url: '/rongyi/resource/' + resourceId,
    method: 'get'
  })
}

// 新增学校
export function addResource (data) {console.log("post data");console.log(data);
  return request({
    url: '/rongyi/resource/',
    method: 'post',
    data: data
  })
}

// 修改学校
export function updateResource (data) {
  return request({
    url: '/rongyi/resource/',
    method: 'put',
    data: data
  })
}

// 删除学校
export function delResource (resourceId) {
  return request({
    url: '/rongyi/resourceResource/' + resourceId,
    method: 'delete'
  })
}
